export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  AUTH_DIAG_ENVIRONMENT: "STAGE",
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://vag9zsh3a1.execute-api.us-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_zWnmXzBYV",
    APP_CLIENT_ID: "2il1o524vpm4i220cu73og8jsd",
    DOMAIN: "https://autoauth-stage-support.auth.us-west-2.amazoncognito.com",
    IDENTITY_POOL_ID: "us-west-2:97d8d05c-3f31-4e46-88ee-4ee90b562a06"
  },
  TRUSTX_PROCESS_DEFINITION_NAME: "eval",
  TRUSTX_PROCESS_DEFINITION_ID: "JDWTDK25FTSLEOIM7GQ2RB2LKY",
  TRUSTX_PROCESS_DEFINITION_VERSION: "4",
};
