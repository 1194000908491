import React, { FC, useState } from 'react';
import { RuntimeConfig, Shop, RefundObj, SupportUser } from '../types';
import { buildReceiptPdf, scalePdf } from '../libs/utils';
import { TextOnly } from './Text';

import { TransactionSubComponent } from '../libs/tables/subComponents';
import { transactionsColumns } from '../libs/tables/columnDefinitions';

import DisplayDialog from './DisplayDialog';
import Dialog from '@reach/dialog';
import GenericTable from './GenericTable';
import GenericPopover from './GenericPopover';
import RefundTransaction from './RefundTransaction';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


interface TransactionsTableDialogProps {
  supportUser: SupportUser;
  currentShop: Shop;
  isOpen: boolean;
  onDismiss: ()=>void;
  config: RuntimeConfig;
  oemInfo: {};
  oemRegionList: any[];
  handleRefundTransaction: any;
}

const TransactionsTableDialog: FC<TransactionsTableDialogProps> = (props: TransactionsTableDialogProps) => {
  const {
    supportUser,
    currentShop,
    isOpen,
    onDismiss,
    config,
    oemInfo,
    oemRegionList,
    handleRefundTransaction,
  } = props;

  const [currentReceipt, setCurrentReceipt] = useState<any>(null);
  const [showReceiptModal, setShowReceiptModal] = useState<boolean>(false);

  const isAdvancedRole = ['MANAGER', 'ADMIN'].includes(supportUser.userType);

  let currentShopTransactions = [] as any[];
  if (currentShop.transactions) {
    currentShopTransactions = (currentShop.transactions as any[])?.map(tran => {
      tran.oemNames = tran.oemIDs?.map((oemID: string) => oemInfo[oemID]);
      if (tran.addedOems && Array.isArray(tran.addedOems)) {
        tran.addedOemNames = tran.addedOems?.map((oemID: string) => oemInfo[oemID]);
      }
      if (tran.refundDetails?.oemIDRefunded) {
        tran.refundDetails.oemNameRefunded = oemInfo[tran.refundDetails.oemIDRefunded];
      }
      return tran;
    });
  }

  const handleViewReceipt = (transactionId, e) => {
    e.preventDefault();
    let transaction = currentShop.transactions.find(
      (trans) => {
        return trans.id === transactionId;
      }
    );

    let receiptDoc = buildReceiptPdf(
      transaction,
      currentShop,
      currentShop.shopUsers?.find((user => user.shopUserRole === 'OWNER')),
      oemRegionList,
    );
    setCurrentReceipt(receiptDoc);
    setShowReceiptModal(true);
  };

  const handleCancelReceiptModal = () => {
    document?.querySelector('.c-modal')?.classList.add('closed');
    setTimeout(() => {
      setShowReceiptModal(false);
    }, 350);
  }

  return (
    <>
      <DisplayDialog
        title={`${currentShop.shopName} Transactions` || 'Transactions'}
        isOpen={isOpen}
        onDismiss={onDismiss}
        is90={true}
      >
        <GenericTable
          id="TRANSACTIONS-table"
          data={currentShopTransactions}
          filterKeys={['amount', 'id', 'status', 'time', 'type', 'paymentMethod']}
          columnDefs={[
            {
              expander: true,
              Header: '',
              accessor: 'expander',
              Expander: ({ isExpanded, ...row }: any) => {
                if (row.original.type === 'paymentRefund' && !row.original.refundDetails) {
                  return null;
                } else {
                  return (
                    <div>
                      <i
                        className={`fa fa-chevron-right ${
                          isExpanded ? 'accordion__icon rotate' : 'accordion__icon'
                        }`}
                        aria-hidden="true"
                      ></i>
                    </div>
                  );
                }
              },
            },
            {
              Header: 'ID',
              accessor: 'id',
              maxWidth: 120,
              Cell: (row: any) => {
                let braintreeUrl =
                config.braintreeMode === 'sandbox'
                    ? `https://sandbox.braintreegateway.com/merchants/${config.merchantId}/transactions/${row.original.id}`
                    : `https://braintreegateway.com/merchants/${config.merchantId}/transactions/${row.original.id}`;
                return (
                  <a
                    href={braintreeUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="u-font-mono"
                  >
                    {row.original.id}
                  </a>
                );
              },
            },
            ...transactionsColumns,
            isAdvancedRole && {
              Header: 'Actions',
              accessor: 'id',
              minWidth: 120,
              maxWidth: 140,
              Cell: (row: any) => {
                const icons: any[] = [];

                // We only show a receipt if the payment went through
                if (!['gateway_rejected', 'processor_declined'].includes(row.original.status)) {
                  icons.push(
                    <button
                      className="c-btn-icon"
                      onClick={handleViewReceipt.bind(this, row.original.id)}
                    >
                      <div className="c-btn__inner">
                        <span
                          className="c-btn__icon fa fa-receipt"
                          title={TextOnly('viewReceipt')}
                        />
                      </div>
                    </button>
                  );
                }

                // If this transaction was refunded or is a refund of another transaction.
                if (row.original.refundedTo || row.original.refundedFrom) {
                  const label = row.original.refundedTo ? 'Refunded To'
                    : row.original.refundedFrom ? 'Refunded From'
                    : null;
                  const refundedDestinations = (row.original.refundedTo || row.original.refundedFrom)?.split(', ').map((id: string) => {
                    const url = config.braintreeMode === 'sandbox'
                      ? `https://sandbox.braintreegateway.com/merchants/${config.merchantId}/transactions/${id}`
                      : `https://braintreegateway.com/merchants/${config.merchantId}/transactions/${id}`;
                    return { id: id, url: url };
                  });

                  icons.push(
                    <GenericPopover
                      anchorElement={
                        <button className="c-btn-icon">
                          <div className="c-btn__inner">
                            <span
                              className="c-btn__icon fa fa-info-circle"
                              title="Info"
                            />
                          </div>
                        </button>
                      }
                      body={
                        <>
                          <strong>{label}: </strong>
                          {refundedDestinations.map((refund: any, i) => {
                            return (
                              <>
                                <a
                                  href={refund.url}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  {refund.id}
                                </a>
                                {i < refundedDestinations.length - 1 && (
                                  <>
                                    {', '}
                                  </>
                                )}
                              </>
                            );
                          })}
                        </>
                      }
                      color="#0088cc"
                      position="top"
                      align="end"
                      arrowSize={5}
                      closeOnClick={true}
                      closeBtn={true}
                    />
                  );
                }

                const hasNeverBeenRefunded = !row.original.refundedTo;
                const hasBeenRefundedLessThanTotal = row.original.refundedAmount && Number(row.original.refundedAmount) < Number(row.original.amount);
                if (
                  row.original.type !== 'paymentRefund' // If the transaction type is not a refund,
                  && !['processor_declined', 'voided', 'gateway_rejected'].includes(row.original.status) // and the payment was processed,
                  && (hasNeverBeenRefunded || hasBeenRefundedLessThanTotal) // and if the transaction was not refunded before or the previous refunds were less than the total transaction.
                ) {
                  icons.push(
                    <RefundTransaction
                      shopID={currentShop.shopID}
                      transaction={row.original}
                      handleRefundTransaction={handleRefundTransaction}
                    />
                  );
                }

                return icons;
              },
            },
          ]}
          defaultSorted={[
            {
              id: 'time',
              desc: true,
            },
          ]}
          savedColumnsId="transactionsTableColumns"
          subComponentRenderer={TransactionSubComponent}
        />
      </DisplayDialog>

      <Dialog
        isOpen={showReceiptModal}
        onDismiss={handleCancelReceiptModal.bind(this)}
        className="c-modal"
        aria-label={TextOnly('autoAuthReceipt')}
      >
        <button
          className="c-btn-icon c-modal__close"
          onClick={handleCancelReceiptModal.bind(this)}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>

        <div className="c-modal__body">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '9rem',
            }}
          >
            <Document
              file={currentReceipt?.data || null}
              loading={TextOnly('loading')}
            >
              <Page pageNumber={1} scale={scalePdf(window.screen.width)} />
            </Document>
          </div>
          <div>
            <button
              className="c-btn-outline"
              onClick={handleCancelReceiptModal.bind(this)}
            >
              Dismiss
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default TransactionsTableDialog;