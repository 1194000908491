import React from 'react';

import { QueriedUser, Shop, Address, PaymentInfo } from '../types';
import {
  userInfoKeymap,
  shopStateInfoKeymap,
  crmStateInfoKeymap,
  addressInfoKeymap,
  paymentInfoKeymap,
  crmPaymentInfoKeymap,
  languageMap,
} from '../libs/tables/tableKeymaps';
import { formatTimezone } from './utils';
import HelpTooltip from '../components/Tooltips/HelpTooltip';
import { getStateVarColor, getShopStateVarDesc } from './utils-ts';
import { Led } from '../components/Led';
import config from '../config';

export const createUserInfoCard = (
  queriedUser: QueriedUser,
  windowWidth: number,
  handleAcceptPI?: Function
) => {
  return Object.keys(queriedUser).map((key) => {
    if (key === 'userShops') {
      return null;
    }
    let value = queriedUser[key as keyof QueriedUser] as any;

    if (!value) return null;

    let monoStyle =
      key !== 'createdOn' && key !== 'language' ? 'u-font-mono' : '';

    if ([
      'createdOn',
      'lastDateAttempted',
      'lastDateSucceded',
      'certTechExpirationDate',
      'certTechIssueDate'
    ].includes(key)) {
      value = formatTimezone(value);
    }

    if (key === 'language') {
      value = languageMap[value as keyof Object] as any;
    }

    if (key === 'userState') {
      value = <Led status={queriedUser.userState as any} />;
    }

    // If the value is a boolean, it must be converted into a string to display in the UI.
    if (typeof value === 'boolean') {
      value = value ? 'true' : 'false';
    }

    if (key === 'certifiedInfo') {
      value = ( value.verificationSessions ?
        <>
          {value.verificationSessions.map((session) => {
            let daonURL =
              `https://ghsissprod.fir.trustx.com/web/backoffice/process-instances/${config.TRUSTX_PROCESS_DEFINITION_ID}/${typeof session == 'string' ? session : session.processInstanceID}`;
            return (
              <div>
                <a
                  href={daonURL}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="u-font-mono u-padding-right-large"
                >
                  {typeof session == 'string' ? session : session.processInstanceID}
                </a>
                <span className="u-padding-right-large">
                  {typeof session == 'string' ? '' : formatTimezone(session.createdOn)}
                </span>
                <span className={"u-padding-right-large" +
                                (session?.status === "Accepted" ? ' u-text-success' :
                                session?.status === "Declined" ? ' u-text-error' :
                                session?.status === "Abandoned" ? ' u-text-error' :
                                session?.status === "Pending" ? ' u-text-warning' : '') }>
                  {typeof session == 'string' ? '' : session.status}
                </span>
                { session?.status === "Accepted" && !queriedUser.idVerified && handleAcceptPI ?
                  <span>
                    <button
                      className="c-btn"
                      onClick={() => {
                        handleAcceptPI(queriedUser.userID, (typeof session == 'string' ? session : session.processInstanceID));
                      }}
                    >
                      <div className="c-btn__inner">Record Acceptance</div>
                    </button>
                  </span>
                  : null
                }
                <span></span>
              </div>
            )
          })}
        </>
      : '')
    }

    return windowWidth < 1220 ? (
      <div className="u-margin-bottom-large" key={key}>
        <label className="mobile-display-label">
          <>{userInfoKeymap[key as keyof Object]}</>
        </label>
        <div className={`mobile-display-value ${monoStyle}`}><>{value}</></div>
      </div>
    ) : (
      <tr className="card-tr" key={key}>
        <th className="card-th">{userInfoKeymap[key as keyof Object]+':'}</th>
        <td className={`card-td ${monoStyle}`}><span>{value}</span></td>
      </tr>
    );
  });
};

export const createShopStateInfoCard = (
  shop: Shop,
  windowWidth: number,
  showAlert?: (
    alertTitle: string,
    alertMessage: string | JSX.Element,
    stringify?: boolean | undefined
  ) => void,
  isCRMCard?: boolean,
) => {
  const keymap = isCRMCard ? crmStateInfoKeymap : shopStateInfoKeymap;
  const subSuspendReason = isCRMCard ? 'crmSuspendReason' : 'suspendReason';
  const subState = isCRMCard ? 'crmState' : 'shopState';

  return Object.keys(keymap).map((key) => {
    let value = shop[key as keyof Shop];

    if (key === 'crmFreeTrialUsed') {
      value = (shop[key as keyof Shop] === true)
        ? 'USED'
        : 'UNUSED'
    } else if (value === 'Canceled') {
      value = 'Canceled';
    }
    if (!value) return null;

    let tooltipContent = (
      <div>
        {getShopStateVarDesc(key, value as string)}
        {shop[subSuspendReason] && shop[subState] === 'SUSPENDED' && (
          <>
            <br />
            <strong>Reason for suspension: </strong>
            {shop[subSuspendReason]}
          </>
        )}
      </div>
    );
    let element = (
      <>
        <span style={{ color: getStateVarColor(value as string) }}>
          <strong>{(value as string).toUpperCase()}</strong>
        </span>

        {getShopStateVarDesc(key, value as string) && (
          <HelpTooltip
            onClick={() =>
              showAlert && showAlert('Info', tooltipContent, false)
            }
            label={
              <div>
                {getShopStateVarDesc(key, value as string)}
                {shop[subSuspendReason] && (shop[subState] === 'TERMINATED' || shop[subState] === 'SUSPENDED') && (
                  <>
                    <br />
                    <strong>Reason for suspension/termination: </strong>
                    {shop[subSuspendReason]}
                  </>
                )}
              </div>
            }
          />
        )}
      </>
    );
    return (
      <React.Fragment key={key}>
        {windowWidth < 1220 ? (
          <div className="u-margin-bottom-large" key={key}>
            <label className="mobile-display-label">
              <>{keymap[key as keyof Object]}</>
            </label>
            <div className={`mobile-display-value l-flex`}>{element}</div>
          </div>
        ) : (
          <tr className="card-tr" key={key}>
            <th className="card-th">
              <>{keymap[key as keyof Object]+': '}</>
            </th>
            <td className="card-td l-inline-flex">{element}</td>
          </tr>
        )}
      </React.Fragment>
    );
  });
};

export const createAddressInfoCard = (shop: Shop | any, windowWidth: number) => {
  if(!shop.addressInfo){
    shop.addressInfo = {
      streetAddress1: shop.streetAddress1,
      streetAddress2: shop.streetAddress2,
      city: shop.city,
      state: shop.state,
      zip: shop.zip,
      country: shop.country
    }
  }
  return Object.keys(addressInfoKeymap).map((key) => {
    let value = '-' as string | undefined;
    if (key === 'phone') {
      value = shop.phone || '-';
    } else {
      value = (shop.addressInfo[key as keyof Address]) || '-';
    }

    return (
      <React.Fragment key={key}>
        {windowWidth < 1220 ? (
          <div className="u-margin-bottom-large" key={key}>
            <label className="mobile-display-label">
              <>{addressInfoKeymap[key as keyof Object]}</>
            </label>
            <div className={`mobile-display-value`}>{value}</div>
          </div>
        ) : (
          <tr className="card-tr" key={key}>
            <th className="card-th">
              <>{addressInfoKeymap[key as keyof Object]+': '}</>
            </th>
            <td className="card-td">{value}</td>
          </tr>
        )}
      </React.Fragment>
    );
  });
};

export const createPaymentInfoCard = (
  shop: Shop,
  windowWidth: number,
  config: any,
  isCRMCard?: boolean
) => {
  let braintreeUrl =
    config.braintreeMode === 'sandbox'
      ? `https://sandbox.braintreegateway.com/merchants/${config.merchantId}`
      : `https://braintreegateway.com/merchants/${config.merchantId}`;

  const keymap = isCRMCard ? crmPaymentInfoKeymap : paymentInfoKeymap;

  return Object.keys(keymap).map((key) => {
    if (!shop.shopPaymentInfo) return null;
    let value = shop.shopPaymentInfo[key as keyof PaymentInfo];
    if (key === 'newBillAmount' && shop.newBillAmount) {
      value = shop.newBillAmount;
    }

    if (!value) return null;

    let element;

    switch (key) {
      case 'customerId':
        element =
          windowWidth >= 1220 ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${braintreeUrl}/customers/${value}`}
              className="u-font-mono"
            >
              <>{value}</>
            </a>
          ) : (
            <div className={`mobile-display-value`}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${braintreeUrl}/customers/${value}`}
                className="u-font-mono"
              >
                <>{value}</>
              </a>
            </div>
          );
        break;

      case 'crmSubscriptionId':
      case 'subscriptionId':
        element =
          windowWidth >= 1220 ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${braintreeUrl}/subscriptions/${value}`}
              className="u-font-mono"
            >
              <>{value}</>
            </a>
          ) : (
            <div className={`mobile-display-value`}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${braintreeUrl}/subscriptions/${value}`}
                className="u-font-mono"
              >
                <>{value}</>
              </a>
            </div>
          );
        break;

      case 'token':
        element =
          windowWidth >= 1220 ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${braintreeUrl}/payment_methods/any/${value}`}
              className="u-font-mono"
            >
              <>{value}</>
            </a>
          ) : (
            <div className={`mobile-display-value`}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${braintreeUrl}/payment_methods/any/${value}`}
                className="u-font-mono"
              >
                <>{value}</>
              </a>
            </div>
          );
        break;

      case 'crmNextBillingDate':
      case 'nextBillingDate':
        let displayValue = <span><>{value}</></span>;
        const subState = isCRMCard ? 'crmSubscriptionState' : 'shopSubscriptionState';
        if (shop[subState] === 'PAST_DUE') {
          displayValue = <span className="text-bold u-text-warning"><strong>PAST DUE</strong></span>;
        }
        element =
          windowWidth < 1220 ? (
            <div className={`mobile-display-value`}>{displayValue}</div>
          ) : (
            displayValue
          );
        break;

      case 'newBillAmount':
        let newBill:number = value as number;
        let displayValue2 = <span className="text-bold u-text-warning"><strong><>{newBill.toFixed(2)}</></strong></span>;
        element =
          windowWidth < 1220 ? (
            <div className={`mobile-display-value`}><>{displayValue2}</></div>
          ) : (
            displayValue2
          );
          break;

      default:
        element =
          windowWidth < 1220 ? (
            <div className={`mobile-display-value`}><>{value}</></div>
          ) : (
            value
          );
        break;
    }

    return windowWidth < 1220 ? (
      <div className="u-margin-bottom-large" key={key}>
        <>
          <label className="mobile-display-label">
            <>{keymap[key as keyof Object]}</>
          </label>
          {element}
        </>
      </div>
    ) : (
      <tr className="card-tr" key={key}>
        <th className="card-th"><>{keymap[key as keyof Object]+':'}</></th>
        <td className="card-td"><>{element}</></td>
      </tr>
    );
  });
};
